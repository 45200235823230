<template>
    <v-row>
        <v-col cols="12" v-if="$parent.$parent.isin(['supers'])">
            <v-btn class="ma-1" large solo depressed color="secondary" :to="{ name: 'dashboard_groups_create' }">
                <v-icon class="mr-2">group_add</v-icon>
                <span class="subtitle-2">Crear nuevo grupo</span>
            </v-btn>
        </v-col>
        <v-col cols="12">
            <DashCard title="Listado de grupos" title-icon="list" body-class="pa-0">
                <div class="pa-4">
                    <v-label>
                        <template v-if="$parent.$parent.isin('cap_evaluador')">
                            Grupos de accesos donde usted ha sido el evaluador asignado.
                        </template>
                        <template v-else>
                            Está viendo todos los grupos de accesos que han sido creados.
                        </template>
                    </v-label>
                </div>
                <v-divider />
                <GrupoTable :items="index" @change="$parent.$parent.reload_dashboard()" :superadmin="$parent.$parent.isin('supers')" />
            </DashCard>
        </v-col>
    </v-row>
</template>

<script>
import DashCard from '@/shared/components/DashCard'
import GrupoTable from '@/educap/components/GrupoTable'
import CommonDialog from '@/shared/components/CommonDialog'
import { axiosHelper } from '@/helpers'

export default {
    components: {
        DashCard,
        GrupoTable,
        CommonDialog
    },
    data: () => ({
        loading: false,
        index: []
    }),
    mounted() {
        this.$parent.$parent.set_title("Grupos de acceso", "group");
        
        axiosHelper.get('edu/grupos/?simple')
                .then(response => {
                    console.log("index: ",response.data);
                    this.index = response.data;
                    this.loading = false;
                })
                .catch(response => {
                    this.index = [];
                    this.loading = false;
                });
    }
}
</script>