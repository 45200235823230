<template>
<div>
    <v-data-table :items="items" :headers="grupos_header" :mobile-breakpoint="0">
        <template v-slot:item.first_curso="{ item }">
            <span v-if="item.cursos_count > 0">
                <v-chip dark label class="ma-1" color="light-blue darken-4" :title="item.first_curso"><v-icon left>folder</v-icon>{{ item.first_curso | see_more(item.cursos_count > 1 ? 15 : 20) }}</v-chip>
                <v-chip dark label v-if="item.cursos_count > 1" color="light-blue accent-4"><b>+{{ item.cursos_count - 1 }}</b></v-chip>
            </span>
            <v-chip dark color="red" v-else>
                <v-icon left>error_outline</v-icon>
                ¡Sin cursos asignados!
            </v-chip>
        </template>
        <template v-slot:item.evaluador="{ item }">
            <v-chip dark color="indigo" v-if="!!item.evaluador">
                <v-icon left>account_circle</v-icon>
                {{ item.evaluador }}
            </v-chip>
            <v-chip dark color="red" v-else>
                <v-icon left>error_outline</v-icon>
                ¡Sin evaluador asignado!
            </v-chip>
        </template>
        <template v-slot:item.nombre="{ item }">
            <span class="d-block">{{ item.nombre }}</span>
            <v-chip v-if="!!item.ref_incidente" dark color="teal">
                <v-icon left>star</v-icon> Cód. {{ item.ref_incidente }}
            </v-chip>
        </template>
        <template v-slot:item.participantes="{ item }">
            <div :class="{'centered-cell': true}">
                {{ item.participantes }}
            </div>
        </template>
        <template v-slot:item.fecha_inicio="{ item }">
            {{ item.fecha_inicio | moment("L") }}
        </template>
        <template v-slot:item.fecha_termino="{ item }">
            {{ item.fecha_termino | moment("L") }}
        </template>
        <template v-slot:item.is_activo="{ item }">
            <v-chip label dark color="green" v-if="!!item.is_activo"> 
                <v-icon left>check</v-icon>
                Disponible
            </v-chip>
            <v-chip label dark color="deep-orange" v-else-if="!item.is_activo && grupo_caducado(item)">
                <v-icon left>close</v-icon>
                Caducado
            </v-chip>
            <v-chip label dark color="teal" v-else>
                Aún no disponible
            </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
            <template v-if="!superadmin">
                <v-btn icon color="primary" v-if="!grupo_caducado(item)" :to="{ name: superadmin ? 'dashboard_groups_edit' : 'dashboard_groups_edit_evaluador', params: { id: item.id } }">
                    <v-icon>edit</v-icon>
                </v-btn>
                <v-btn icon color="primary" v-else :to="{ name: 'dashboard_groups_item', params: { id: item.id } }">
                    <v-icon>visibility</v-icon>
                </v-btn>
            </template>
            <template v-else>
                <v-btn icon color="primary" v-if="!superadmin" :to="{ name: 'dashboard_groups_edit_evaluador', params: { id: item.id } }">
                    <v-icon>edit</v-icon>
                </v-btn>
                <v-menu offset-y v-else>
                    <template v-slot:activator="{ on }">
                        <v-btn fab small text color="primary" depressed v-on="on">
                            <v-icon>more_vert</v-icon>
                        </v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item :to="{ name: 'dashboard_groups_item', params: { id: item.id } }">
                            <v-list-item-icon><v-icon>visibility</v-icon></v-list-item-icon>
                            <v-list-item-title>Ver</v-list-item-title>
                        </v-list-item>
                        <v-list-item :to="{ name: superadmin ? 'dashboard_groups_edit' : 'dashboard_groups_edit_evaluador', params: { id: item.id, caducado: grupo_caducado(item) } }">
                            <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                            <v-list-item-title>Editar</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="ask_delete(item)" v-if="superadmin && !grupo_caducado(item)">
                            <v-list-item-icon><v-icon>close</v-icon></v-list-item-icon>
                            <v-list-item-title>Eliminar</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </template>
    </v-data-table>
    <CommonDialog ref="delete_dialog" @onClose="delete_group" />
    <Message ref="dialog" />
</div>
</template>

<script>
import CommonDialog from '@/shared/components/CommonDialog'
import Message from '@/shared/components/Message'
import { axiosHelper, messages } from '@/helpers'
import { user_isin } from '@/helpers/utils'
import moment from 'moment'

export default {
    props: {
        items: {
            type: Array,
            default: () => ([])
        },
        superadmin: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Message,
        CommonDialog
    },
    data: () => ({
        grupos_header: [
            { value: "nombre", text: "Nombre" },
            { value: "first_curso", text: "Cursos" },
            { value: "fecha_inicio", text: "Inicio" },
            { value: "fecha_termino", text: "Termino" },
            { value: "participantes", text: "Participantes" },
            { value: "evaluador", text: "Evaluador" },
            { value: "is_activo", text: "Estado" },
            { value: "actions", text: "", sortable: false, width: '32px' }
        ]
    }),
    methods: {
        grupo_caducado(item) {
            var termino = moment(item.fecha_termino).add(1, 'day');
            var ahora = moment();
            return ahora > termino;
        },
        isin(user_groups, groups) {
            return user_isin(user_groups, groups);
        },
        ask_delete(item) {
            this.$refs.delete_dialog.showDialog(messages.titles['question_delete_group'], messages.texts['question_delete_group'], 'yes_no', item);
        },
        show_error() {
            this.$refs.dialog.show(messages.titles['error_generico'], messages.texts['error_desconocido'], 'error');
        },
        show_editor(item) {
            this.$refs.pass_dialog.show(item);
        },
        delete_group(response, item) {
            if(response == "yes") {
                axiosHelper.del('edu/grupos/' + item.id + '/')
                    .then(() => this.$emit('change'))
                    .catch(() => this.show_error());
            }
        }
    }
}
</script>
<style>
.centered-cell {
  text-align: center;
}
</style>